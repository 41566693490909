import AuthGuard from "auth/AuthGuard";
import { LoginCallback } from "auth/LoginCallback";
import { PermissionGuard, RoleGuard } from "auth/PermissionGuard";
import { FeatureFlagGuard } from "components/FeatureFlagGuard";
import Loading from "components/Loading";
import { LogoutIdleTimer } from "components/LogoutIdleTimer";
import { useBackendHealthService } from "hooks/useBackendHealthService";
import useFeatureFlag from "hooks/useFeatureFlag";
import type { ElementType } from "react";
import { Suspense, lazy } from "react";
import { Navigate, Outlet, useRoutes } from "react-router-dom";

function Loadable(Component: ElementType) {
  return function LoadableComponent<Props extends object>(props: Props) {
    return (
      <Suspense fallback={<Loading />}>
        <Component {...props} />
      </Suspense>
    );
  };
}

const DashboardPage = Loadable(
  lazy(() =>
    import("pages/DashboardPage").then((module) => ({
      default: module.DashboardPage,
    })),
  ),
);
const CampaignsPage = Loadable(
  lazy(() =>
    import("pages/campaigns/CampaignsPage").then((module) => ({
      default: module.CampaignsPage,
    })),
  ),
);
const CreateOrEditCampaignPage = Loadable(
  lazy(() =>
    import("pages/campaigns/CreateOrEditCampaignPage").then((module) => ({
      default: module.CreateOrEditCampaignPage,
    })),
  ),
);
const CampaignDetailsPage = Loadable(
  lazy(() =>
    import("pages/campaigns/CampaignDetailsPage").then((module) => ({
      default: module.CampaignDetailsPage,
    })),
  ),
);
const CampaignApplicationsPage = Loadable(
  lazy(() =>
    import("pages/campaigns/CampaignApplicationsPage").then((module) => ({
      default: module.CampaignApplicationsPage,
    })),
  ),
);
const CampaignOrdersPage = Loadable(
  lazy(() =>
    import("pages/campaigns/CampaignOrdersPage").then((module) => ({
      default: module.CampaignOrdersPage,
    })),
  ),
);
const CampaignOrderDetailsPage = Loadable(
  lazy(() =>
    import("pages/campaigns/CampaignOrderDetailsPage").then((module) => ({
      default: module.CampaignOrderDetailsPage,
    })),
  ),
);
const CustomersPage = Loadable(
  lazy(() =>
    import("pages/customers/CustomersPage").then((module) => ({
      default: module.CustomersPage,
    })),
  ),
);
const CustomerDetailsPage = Loadable(
  lazy(() =>
    import("pages/customers/CustomerDetailsPage").then((module) => ({
      default: module.CustomerDetailsPage,
    })),
  ),
);
const ProfilePage = Loadable(
  lazy(() =>
    import("pages/ProfilePage").then((module) => ({
      default: module.ProfilePage,
    })),
  ),
);
const HukCampaignsPage = Loadable(
  lazy(() =>
    import("pages/campaigns/HukCampaignsPage").then((module) => ({
      default: module.HukCampaignsPage,
    })),
  ),
);
const GSDetailsPage = Loadable(
  lazy(() =>
    import("pages/campaigns/GSDetailsPage").then((module) => ({
      default: module.GSDetailsPage,
    })),
  ),
);
const QuotationPage = Loadable(
  lazy(() =>
    import("pages/QuotationPage").then((module) => ({
      default: module.QuotationPage,
    })),
  ),
);
const MaintenancePage = Loadable(
  lazy(() =>
    import("pages/MaintenancePage").then((module) => ({
      default: module.MaintenancePage,
    })),
  ),
);
const NotFound = Loadable(lazy(() => import("pages/NotFound")));

const LegacyHukCampaignsPage = Loadable(
  lazy(() => import("pages/legacy/HukCampaignsPage")),
);
const LegacyGsDetailPage = Loadable(
  lazy(() => import("pages/legacy/GsDetailPage")),
);
const LegacyDestinationsPage = Loadable(
  lazy(() => import("pages/legacy/admin/DestinationsPage")),
);

function Router() {
  const { enabled: isMaintenancePageEnabled } = useFeatureFlag("maintenance");
  const { isBackendHealthy } = useBackendHealthService(
    isMaintenancePageEnabled,
  );

  if (isMaintenancePageEnabled) {
    if (isBackendHealthy == null) return <Loading />;

    if (!isBackendHealthy)
      return useRoutes([
        {
          path: "*",
          element: <Navigate replace to="/maintenance" />,
        },
        {
          path: "/maintenance",
          element: <MaintenancePage />,
        },
      ]);
  }

  return useRoutes([
    {
      path: "/",
      element: (
        <AuthGuard>
          <LogoutIdleTimer>
            <Outlet />
          </LogoutIdleTimer>
        </AuthGuard>
      ),
      children: [
        {
          element: <Navigate replace to="/dashboard" />,
          index: true,
        },
        { path: "dashboard", element: <DashboardPage /> },
        {
          path: "campaigns",
          children: [
            { element: <CampaignsPage />, index: true },
            {
              path: ":id",
              element: <CampaignDetailsPage />,
            },
            {
              path: ":id/edit",
              element: <CreateOrEditCampaignPage isEdit />,
            },
            {
              path: "create",
              element: <CreateOrEditCampaignPage />,
            },
            {
              path: "applications",
              element: <CampaignApplicationsPage />,
            },
            {
              path: "orders",
              element: <CampaignOrdersPage />,
            },
            {
              path: "orders/:id",
              element: <CampaignOrderDetailsPage />,
            },
          ],
        },
        {
          path: "customers",
          element: <PermissionGuard requiredPermission="write:user" />,
          children: [
            { element: <CustomersPage />, index: true },
            {
              path: ":id",
              element: <CustomerDetailsPage />,
            },
          ],
        },
        { path: "profile", element: <ProfilePage /> },
        {
          path: "huk/gs",
          children: [
            { element: <HukCampaignsPage />, index: true },
            {
              path: ":id",
              element: <GSDetailsPage />,
            },
          ],
        },
        {
          path: "quotation-form",
          element: (
            <FeatureFlagGuard flag="quotations">
              <PermissionGuard requiredPermission="create:quotation">
                <QuotationPage />
              </PermissionGuard>
            </FeatureFlagGuard>
          ),
        },
        { path: "legacy/huk/gs", element: <LegacyHukCampaignsPage /> },
        {
          path: "legacy/huk/gs/:id/",
          element: <LegacyGsDetailPage />,
        },
        {
          path: "legacy/admin",
          element: (
            <RoleGuard allowedRoles={["woop_admin", "creator", "support"]} />
          ),
          children: [
            {
              path: "destinations",
              element: <LegacyDestinationsPage />,
            },
          ],
        },
      ],
    },
    { path: "/callback", element: <LoginCallback /> },
    { path: "/maintenance", element: <Navigate replace to="/" /> },
    { path: "404", element: <NotFound /> },
    { path: "*", element: <Navigate replace to="/404" /> },
  ]);
}

export default Router;
