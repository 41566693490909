import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogTitle,
} from "components/ui/AlertDialog";
import {
  createContext,
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

interface ConfirmDialogProps {
  title?: string;
  description?: string;
  confirmationText?: string;
  cancellationText?: string;
  isDestructive?: boolean;
}

interface ConfirmationContextType {
  confirm: (props?: ConfirmDialogProps) => Promise<boolean>;
}

const ConfirmationContext = createContext<ConfirmationContextType | undefined>(
  undefined,
);

function useConfirmationContext() {
  return useContext(ConfirmationContext);
}

interface Props {
  readonly children: ReactNode;
}

function ConfirmationProvider({ children }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [dialogProps, setDialogProps] = useState<ConfirmDialogProps>({});
  const [resolvePromise, setResolvePromise] = useState<
    ((value: boolean) => void) | null
  >(null);
  const [rejectPromise, setRejectPromise] = useState<
    ((reason?: any) => void) | null
  >(null);

  const confirm = useCallback(
    (props: ConfirmDialogProps = {}): Promise<boolean> => {
      return new Promise((resolve, reject) => {
        setDialogProps(props);
        setIsOpen(true);
        setResolvePromise(() => resolve);
        setRejectPromise(() => reject);
      });
    },
    [],
  );

  function handleConfirm() {
    setIsOpen(false);
    resolvePromise && resolvePromise(true);
  }

  function handleCancel() {
    rejectPromise && rejectPromise();
  }

  useEffect(() => {
    !isOpen && handleCancel();
  }, [isOpen]);

  const providerValue = useMemo(() => ({ confirm }), [confirm]);

  return (
    <ConfirmationContext.Provider value={providerValue}>
      {children}
      <AlertDialog onOpenChange={setIsOpen} open={isOpen}>
        <AlertDialogContent>
          <AlertDialogTitle>
            {dialogProps.title ?? "Bestätigung erforderlich"}
          </AlertDialogTitle>
          {dialogProps.description != null && (
            <AlertDialogDescription>
              {dialogProps.description}
            </AlertDialogDescription>
          )}
          <AlertDialogFooter>
            <AlertDialogCancel>
              {dialogProps.cancellationText ?? "Abbrechen"}
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={handleConfirm}
              variant={
                dialogProps.isDestructive ?? true ? "destructive" : "active"
              }
            >
              {dialogProps.confirmationText ?? "Bestätigen"}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </ConfirmationContext.Provider>
  );
}

export { ConfirmationProvider, useConfirmationContext };
