import {
  createTheme,
  darken,
  lighten,
  PaletteColorOptions,
} from "@mui/material";
import { alpha, PaletteOptions, TypeAction } from "@mui/material/styles";
import type { SimplePaletteColorOptions } from "@mui/material/styles/createPalette";
import { CB_RED } from "components/legacy/contact_board/colors";

function createGradient(color1: string, color2: string) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

export type ColorSchema =
  | "primary"
  | "secondary"
  | "info"
  | "success"
  | "warning"
  | "error";

interface GradientsPaletteOptions {
  primary: string;
  info: string;
  success: string;
  warning: string;
  error: string;
}

interface ChartPaletteOptions {
  violet: string[];
  blue: string[];
  green: string[];
  yellow: string[];
  red: string[];
}

declare module "@mui/material/styles/createPalette" {
  interface TypeBackground {
    neutral: string;
  }

  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }

  interface PaletteColor {
    lighter: string;
    darker: string;
  }

  interface Palette {
    gradients: GradientsPaletteOptions;
    chart: ChartPaletteOptions;
  }

  interface PaletteOptions {
    gradients: GradientsPaletteOptions;
    chart: ChartPaletteOptions;
  }
}

// Add custom colors here
declare module "@mui/material/styles" {
  interface CustomPalette {
    darkRed: PaletteColorOptions;
    darkBlue: PaletteColorOptions;
    darkGreen: PaletteColorOptions;
    cbRed: PaletteColorOptions;
  }

  interface Palette extends CustomPalette {}

  interface PaletteOptions extends CustomPalette {}
}

// See here for more information: https://stackoverflow.com/questions/46486565/mui-customize-button-color
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    darkRed: true;
    cbRed: true;
    darkBlue: true;
    darkGreen: true;
    black: true;
  }
}

declare module "@mui/material" {
  interface Color {
    0: string;
    500_8: string;
    500_12: string;
    500_16: string;
    500_24: string;
    500_32: string;
    500_48: string;
    500_56: string;
    500_80: string;
  }
}

function generateShades(color: any): CustomColor {
  return {
    lighter: lighten(color, 0.6),
    light: lighten(color, 0.3),
    main: color,
    dark: darken(color, 0.3),
    darker: darken(color, 0.6),
  };
}

// Logo colors
const LOGO_COLORS = {
  plumpPurple: "#5c4b9a",
  fandangoPink: "#e93e8a",
  burntSienna: "#ee704b",
  mandarin: "#ed7d4d",
  persianOrange: "#eb8c4e",
  sandyBrown: "#e89f4f",
  maximumYellowRed: "#e5b64f",
  arylideYellow: "#dfcc4c",
  straw: "#ddd24b",
};

// SETUP COLORS
const PRIMARY: CustomColor = generateShades("#4883D0");
const SECONDARY: CustomColor = generateShades(LOGO_COLORS.mandarin);

const SECONDARY_DARK = generateShades(LOGO_COLORS.fandangoPink);

interface CustomColor extends SimplePaletteColorOptions {
  lighter: string;
  light: string;
  main: string;
  dark: string;
  darker: string;
}

const INFO: CustomColor = {
  lighter: "#D0F2FF",
  light: "#74CAFF",
  main: "#1890FF",
  dark: "#0C53B7",
  darker: "#04297A",
};
const SUCCESS: CustomColor = {
  lighter: "#E9FCD4",
  light: "#AAF27F",
  main: "#54D62C",
  dark: "#229A16",
  darker: "#08660D",
};
const WARNING: CustomColor = {
  lighter: "#FFF7CD",
  light: "#FFE16A",
  main: "#FFC107",
  dark: "#B78103",
  darker: "#7A4F01",
};
const ERROR: CustomColor = {
  lighter: "#FFE7D9",
  light: "#FFA48D",
  main: "#FF4842",
  dark: "#B72136",
  darker: "#7A0C2E",
};

const GREY = {
  0: "#FFFFFF",
  50: "#FcFcFc",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#212B36",
  900: "#161C24",
  500_8: alpha("#919EAB", 0.08),
  500_12: alpha("#919EAB", 0.12),
  500_16: alpha("#919EAB", 0.16),
  500_24: alpha("#919EAB", 0.24),
  500_32: alpha("#919EAB", 0.32),
  500_48: alpha("#919EAB", 0.48),
  500_56: alpha("#919EAB", 0.56),
  500_80: alpha("#919EAB", 0.8),
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
  violet: ["#826AF9", "#9E86FF", "#D0AEFF", "#F7D2FF"],
  blue: ["#2D99FF", "#83CFFF", "#A5F3FF", "#CCFAFF"],
  green: ["#2CD9C5", "#60F1C8", "#A4F7CC", "#C0F2DC"],
  yellow: ["#FFE700", "#FFEF5A", "#FFF7AE", "#FFF3D6"],
  red: ["#FF6C40", "#FF8F6D", "#FFBD98", "#FFF2D4"],
};
const { palette: _palette } = createTheme();
const { augmentColor } = _palette;
const createColor = (mainColor: string) => {
  const shades = generateShades(mainColor);
  return augmentColor({
    color: shades,
  });
};
const COMMON: CustomPalette = {
  common: { black: "#000", white: "#fff" },
  primary: { ...PRIMARY, contrastText: "#fff" },
  secondary: { ...SECONDARY, contrastText: "#fff" },
  info: { ...INFO, contrastText: "#fff" },
  success: { ...SUCCESS, contrastText: "#fff" },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: "#fff" },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
  darkRed: createColor("#EA6251"),
  darkGreen: {
    ...createColor("#A3D987"),
    contrastText: "#fff",
  },
  darkBlue: {
    ...createColor("#009EF7"),
    contrastText: "#fff",
  },
  cbRed: createColor(CB_RED),
  black: createColor("#000000"),
};

interface CustomPalette extends PaletteOptions {
  primary: CustomColor;
  secondary: CustomColor;
  info: CustomColor;
  success: CustomColor;
  warning: CustomColor;
  error: CustomColor;
  grey: { [key in keyof typeof GREY]: string };
  gradients: GradientsPaletteOptions;
  chart: ChartPaletteOptions;
  divider: string;
  darkRed: CustomColor;
  darkBlue: CustomColor;
  darkGreen: CustomColor;
  cbRed: CustomColor;
  black: CustomColor;
  action: Partial<TypeAction>;
}

const lightPalette: CustomPalette = {
  ...COMMON,
  mode: "light",
  text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
  background: {
    paper: "#fff",
    default: GREY[50],
    neutral: GREY[200],
    // @ts-ignore
    body: GREY[50],
  },
  action: { active: GREY[600], ...COMMON.action },
};
const darkPalette: CustomPalette = {
  ...COMMON,
  mode: "dark",
  text: { primary: "#fff", secondary: GREY[500], disabled: GREY[600] },
  background: { paper: GREY[800], default: GREY[900], neutral: GREY[500_16] },
  action: { active: GREY[500], ...COMMON.action },
};
const palette = {
  light: lightPalette,
  dark: darkPalette,
} as const;

export default palette;
