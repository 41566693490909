import type {
  Permission,
  UserRole,
} from "../../../../../../contexts/UserContext";

export type SortDirection = "ascending" | "descending";

export type SortKey = "start" | "end" | "name" | "organization" | "contacts";

export interface Sort {
  key: SortKey;
  displayText: string;
  displayPredicate?: (
    role: UserRole | null,
    hasPermission: (permission: Permission) => boolean,
    isNotCustomer: boolean,
  ) => boolean;
}

export const sortOptions: Sort[] = [
  {
    key: "start",
    displayText: "Startdatum",
  },
  {
    key: "end",
    displayText: "Enddatum",
  },
  {
    key: "name",
    displayText: "Name",
  },
  {
    key: "organization",
    displayText: "Kunde",
    displayPredicate: (roles, hasPermission, isNotCustomer) => isNotCustomer,
  },
  {
    key: "contacts",
    displayText: "Bewerber (Gesamt)",
  },
];

export const defaultSort: SortKey = "start";

export const defaultSortDirection: SortDirection = "descending";
