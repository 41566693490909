import { MultiCombobox } from "components/ui/MultiCombobox";
import { useGetDistinctResponsiblePersonsQuery } from "generated/graphql";

function ResponsiblePersonFilterDropdown({
  values,
  setValues,
}: {
  readonly values: string[];
  readonly setValues: (values: string[]) => void;
}) {
  const { data: distincResponsiblePersons } =
    useGetDistinctResponsiblePersonsQuery();
  const options: string[] = (distincResponsiblePersons?.distinct_persons ?? [])
    .map((c) => c.responsible_person)
    .filter((v): v is string => v != null);

  return (
    <MultiCombobox
      defaultValue={values}
      onValueChange={setValues}
      options={options.map((option) => ({
        label: option,
        value: option,
      }))}
      placeholder="Zuständige Person"
    />
  );
}

export { ResponsiblePersonFilterDropdown };
