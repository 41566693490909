import { MultiCombobox } from "components/ui/MultiCombobox";
import { useGetLocationsQuery } from "generated/graphql";

function LocationFilterDropdown({
  setValues,
  values,
}: {
  readonly setValues: (values: string[]) => void;
  readonly values: string[];
}) {
  const { data: locations } = useGetLocationsQuery();

  if (
    locations?.organization_location === undefined ||
    locations.organization_location.length === 0
  )
    return;

  return (
    <MultiCombobox
      defaultValue={values}
      onValueChange={setValues}
      options={locations.organization_location.map(({ name }) => ({
        label: name,
        value: name,
      }))}
      placeholder="Standorte auswählen"
    />
  );
}

export { LocationFilterDropdown };
