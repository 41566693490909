import { cva, type VariantProps } from "class-variance-authority";
import { Badge } from "components/ui/Badge";
import { Button } from "components/ui/Button";
import { Checkbox } from "components/ui/Checkbox";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "components/ui/Command";
import { Icons } from "components/ui/Icons";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/Popover";
import { Separator } from "components/ui/Separator";
import { cn } from "lib/utils";
import * as React from "react";

const multiComboboxVariants = cva(
  "m-1 max-w-full overflow-hidden transition delay-150 duration-300 ease-in-out",
  {
    variants: {
      variant: {
        default: "border-gray-300 bg-gray-200 text-gray-700 hover:bg-gray-200",
        secondary:
          "border-transparent bg-gray-200 text-gray-700 hover:bg-gray-300",
        destructive:
          "border-transparent bg-danger-light text-danger-active hover:bg-danger/20",
        inverted: "inverted",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

interface MultiComboboxProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof multiComboboxVariants> {
  readonly options: {
    label: string;
    value: string;
    icon?: React.ComponentType<{ className?: string }>;
  }[];
  readonly onValueChange: (value: string[]) => void;
  readonly defaultValue: string[];
  readonly placeholder?: string;
  readonly maxCount?: number;
  readonly isAutoSuggest?: boolean;
  readonly asChild?: boolean;
  readonly className?: string;
}

const MultiCombobox = React.forwardRef<HTMLButtonElement, MultiComboboxProps>(
  (
    {
      options,
      onValueChange,
      variant,
      defaultValue = [],
      placeholder = "Select options",
      maxCount = 3,
      isAutoSuggest = false,
      asChild = false,
      className,
      ...props
    },
    ref,
  ) => {
    const [selectedValues, setSelectedValues] =
      React.useState<string[]>(defaultValue);
    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
    const [inputValue, setInputValue] = React.useState("");
    const [localOptions, setLocalOptions] = React.useState(options);

    React.useEffect(() => {
      if (JSON.stringify(selectedValues) !== JSON.stringify(defaultValue)) {
        setSelectedValues(defaultValue);
      }
    }, [defaultValue, selectedValues]);

    React.useEffect(() => {
      setLocalOptions(options);
    }, [options]);

    const handleInputChange = (value: string) => {
      setInputValue(value);
    };

    const handleInputKeyDown = (
      event: React.KeyboardEvent<HTMLInputElement>,
    ) => {
      if (event.key === "Enter" && isAutoSuggest && inputValue.trim()) {
        addNewOption(inputValue.trim());
      } else if (event.key === "Backspace" && !event.currentTarget.value) {
        removeLastSelectedValue();
      }
    };

    const addNewOption = (value: string) => {
      const newOption = { label: value, value: value };
      if (!localOptions.some((option) => option.value === value)) {
        setLocalOptions((prevOptions) => [...prevOptions, newOption]);
      }
      if (!selectedValues.includes(value)) {
        const newSelectedValues = [...selectedValues, value];
        setSelectedValues(newSelectedValues);
        onValueChange(newSelectedValues);
      }
      setInputValue("");
    };

    const removeLastSelectedValue = () => {
      const newSelectedValues = [...selectedValues];
      const removedValue = newSelectedValues.pop();
      if (removedValue) {
        setSelectedValues(newSelectedValues);
        onValueChange(newSelectedValues);
        if (!options.some((option) => option.value === removedValue)) {
          setLocalOptions((prevOptions) =>
            prevOptions.filter((option) => option.value !== removedValue),
          );
        }
      }
    };

    const toggleOption = (value: string) => {
      const newSelectedValues = selectedValues.includes(value)
        ? selectedValues.filter((v) => v !== value)
        : [...selectedValues, value];
      setSelectedValues(newSelectedValues);
      onValueChange(newSelectedValues);
    };

    const handleClear = () => {
      setSelectedValues([]);
      onValueChange([]);
      setLocalOptions(options);
    };

    const handleTogglePopover = () => {
      setIsPopoverOpen((prev) => !prev);
    };

    const clearExtraOptions = () => {
      const newSelectedValues = selectedValues.slice(0, maxCount);
      setSelectedValues(newSelectedValues);
      onValueChange(newSelectedValues);
      setLocalOptions((prevOptions) =>
        prevOptions.filter(
          (option) =>
            options.some((o) => o.value === option.value) ||
            newSelectedValues.includes(option.value),
        ),
      );
    };

    const toggleAll = () => {
      if (selectedValues.length === localOptions.length) {
        handleClear();
      } else {
        const allValues = localOptions.map((option) => option.value);
        setSelectedValues(allValues);
        onValueChange(allValues);
      }
    };

    const filteredOptions = localOptions.filter((option) =>
      option.value.toLowerCase().includes(inputValue.toLowerCase()),
    );

    return (
      <Popover modal onOpenChange={setIsPopoverOpen} open={isPopoverOpen}>
        <PopoverTrigger asChild>
          <Button
            ref={ref}
            {...props}
            className={cn(
              "bg-inherit hover:bg-inherit flex h-auto min-h-10 w-full items-center justify-between rounded-md border border-gray-300 p-1",
              className,
            )}
            onClick={handleTogglePopover}
          >
            {selectedValues.length > 0 ? (
              <div className="flex w-full max-w-full items-center justify-between">
                <div className="flex grow flex-wrap items-center overflow-hidden">
                  {selectedValues.slice(0, maxCount).map((value) => {
                    const option = localOptions.find((o) => o.value === value);
                    const IconComponent = option?.icon;
                    return (
                      <Badge
                        className={multiComboboxVariants({
                          variant,
                          className,
                        })}
                        key={value}
                      >
                        {IconComponent && (
                          <IconComponent className="mr-2 size-4" />
                        )}
                        <span className="truncate">
                          {option?.label || value}
                        </span>
                        <Icons.CrossCircle
                          className="ml-2 size-4 shrink-0 cursor-pointer"
                          onClick={(event) => {
                            event.stopPropagation();
                            toggleOption(value);
                          }}
                        />
                      </Badge>
                    );
                  })}
                  {selectedValues.length > maxCount && (
                    <Badge
                      className={cn(
                        "text-foreground border-gray-100 bg-transparent hover:bg-transparent",
                        multiComboboxVariants({ variant, className }),
                      )}
                    >
                      {`+${selectedValues.length - maxCount} weitere`}
                      <Icons.CrossCircle
                        className="ml-2 size-4 cursor-pointer"
                        onClick={(event) => {
                          event.stopPropagation();
                          clearExtraOptions();
                        }}
                      />
                    </Badge>
                  )}
                </div>
                <div className="flex shrink-0 items-center justify-between">
                  <div
                    className="mx-2 text-gray-700"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleClear();
                    }}
                  >
                    <Icons.Cross className="size-4" />
                  </div>
                  <Separator
                    className="flex h-full min-h-6"
                    orientation="vertical"
                  />
                  <Icons.ChevronDown className="mx-2 size-4 cursor-pointer text-gray-700" />
                </div>
              </div>
            ) : (
              <div className="mx-auto flex w-full items-center justify-between">
                <span className="mx-2 text-sm text-gray-700">
                  {placeholder}
                </span>
                <Icons.ChevronDown className="mx-2 size-4 cursor-pointer text-gray-700" />
              </div>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          align="start"
          className="w-[19rem] p-0"
          onEscapeKeyDown={() => setIsPopoverOpen(false)}
        >
          <Command>
            <CommandInput
              onKeyDown={handleInputKeyDown}
              onValueChange={handleInputChange}
              placeholder="Suchen"
              value={inputValue}
            />
            <CommandList>
              <CommandEmpty>No results.</CommandEmpty>
              <CommandGroup>
                {!isAutoSuggest && (
                  <CommandItem
                    className="cursor-pointer"
                    key="all"
                    onSelect={toggleAll}
                    style={{ pointerEvents: "auto", opacity: 1 }}
                  >
                    <Checkbox
                      checked={selectedValues.length === localOptions.length}
                      className="mr-2 size-4 rounded"
                    />
                    <span className="uppercase">(Alle auswählen)</span>
                  </CommandItem>
                )}
                {isAutoSuggest &&
                  inputValue.trim() &&
                  !filteredOptions.length && (
                    <CommandItem
                      className="cursor-pointer"
                      key="new"
                      onSelect={() => addNewOption(inputValue.trim())}
                    >
                      <span className="truncate">
                        &apos;{inputValue.trim()}&apos; hinzufügen
                      </span>
                    </CommandItem>
                  )}
                {filteredOptions.map((option) => {
                  const isSelected = selectedValues.includes(option.value);
                  return (
                    <CommandItem
                      className="cursor-pointer"
                      key={option.value}
                      onSelect={() => toggleOption(option.value)}
                      style={{ pointerEvents: "auto", opacity: 1 }}
                    >
                      <Checkbox
                        checked={isSelected}
                        className="mr-2 size-4 rounded"
                      />
                      {option.icon && (
                        <option.icon className="mr-2 size-4 text-gray-700" />
                      )}
                      <span className="truncate">{option.label}</span>
                    </CommandItem>
                  );
                })}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    );
  },
);
MultiCombobox.displayName = "MultiCombobox";

export { MultiCombobox };
